<template>
  <div
    v-if="isCustomQuestionShown"
    id="gf-epl-dollar-handles"
    class="row m-0 p-0 w-100"
  >
    <component
      :is="settings.type"
      :settings="settings"
      @action="(eventData) => this.$emit('action', eventData)"
    />
  </div>
</template>
<script>
import { actions } from "../../../../common/conditionalLogic/ConditionalLogicConfig";
import ModuleBase from "../ModuleBase.vue";
import Checkbox from "./Checkbox.vue";
import Options from "./Options.vue";
import Freetext from "./FreeText.vue";

export default {
	name: "CustomQuestion",
    components: {
      Checkbox,
	    Options,
      Freetext,
    },
	mixins: [ModuleBase],
  data() {
    return {
      isParentModule: true,
      isCustomQuestionShown: true,
    }
  },
	mounted() {
		this.logger.logInfo(this.settings,'Custom question Module loaded')

        this.registerAction(actions.shown, () => this.isCustomQuestionShown = true);
        this.registerAction(actions.hide, () => this.isCustomQuestionShown = false);
	}
};
</script>

