<template>
  <input
    id="IntlTelInput"
    v-model="mobile"
    :class="['custom-intl-tel-input', {'custom-intl-tel-input--invalid' : !isValid}]"
  >
</template>
<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';


export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                local: '',
                complete: '',
                isValid: true,
            }),
        },
        settings: {
            type: Object,
            default: () => null,
        }
    },
    data() {
        return {
            mobile: '',
            isValid: false,
            validationError: null,
            intlTelInputInstance: null,
        }
    },
    watch: {
        value: {
            handler: function(val){
                this.mobile = val.local;
                this.isValid = val.isValid;
            },
            immediate: true,
            deepL: true,
        },
        mobile: {
            handler: function(val){
                this.validate();
                const completeNumber = this.intlTelInputInstance ? this.intlTelInputInstance.getNumber() : '';
                this.$emit('input', {
                    local: val,
                    complete: completeNumber,
                    isValid: (completeNumber && completeNumber.length > 0) ? this.isValid : true,
                });
            },
            immediate: true,
        }
    },
    mounted(){
        const intlTelInputEl = document.querySelector('#IntlTelInput');
        if(intlTelInputEl)
        {
            const options ={
                separateDialCode: true,
                customPlaceholder: function(selectedCountryPlaceholder) {
                        return "e.g. " + selectedCountryPlaceholder;
                },
            };
            if(this.settings && this.settings.regionCode)
                options.initialCountry = this.settings.regionCode;
            this.intlTelInputInstance = intlTelInput(intlTelInputEl, options);
        }
    },
    methods: {
        validate(){
            this.isValid = this.intlTelInputInstance && this.intlTelInputInstance.isValidNumber();
        }
    }
}
</script>
<style lang="css" >
.custom-intl-tel-input{
    padding: 13px;
    width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.2);
    border-radius: 5px;
    outline: none;
}
.custom-intl-tel-input--invalid{
    border-color: #dc3545 !important;
}
.iti {
    width: 100% !important;
}
</style>
