<template>
  <div
    id="gf-epl-dollar-handles"
    class="row m-0 p-0 w-100"
  >
    <component
      :is="settings.type"
      :settings="settings"
      @action="(eventData) => this.$emit('action', eventData)"
    />
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import Checkbox from "./Checkbox.vue";
import Options from "./Options.vue";

export default {
	name: "CustomQuestion",
    components: {
      Checkbox,
	    Options,
    },
	mixins: [ModuleBase],
  data() {
    return {
      isParentModule: true
    }
  },
	mounted() {
		this.logger.logInfo(this.setings,'Custom question Module loaded')
	}
};
</script>

