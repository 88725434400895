import Vue from 'vue';
import {resolveFieldAlias, resolveModuleAlias} from './ConditionalLogicConfig';

export default {
    state: Vue.observable({}),
    initializeStateViaPages(pages){
        pages.length && pages.forEach((page => {
            page.contents && page.contents.forEach((content) => {
                content.conditions && content.conditions.forEach(condition => {
                    if (!condition.dependsOnModule ||
                        !condition.dependsOnModule.length ||
                        !condition.property ||
                        !condition.property.length
                    ) return;

                    if (!this.state[resolveModuleAlias(condition.dependsOnModule)]) {
                        Vue.set(this.state, resolveModuleAlias(condition.dependsOnModule), {});
                      }

                    Vue.set(this.state[resolveModuleAlias(condition.dependsOnModule)], resolveFieldAlias(condition.property), null);
                })
            })
        }))

    },
    setState(module, property, value) {
        if (!this.state[module]) {
          Vue.set(this.state, module, {});
        }
        Vue.set(this.state[module], property, value);
    },
}
