/**
 * The aliasing serves as abstraction layer between our internal variables names and external usage, the alias itself should not change but internal variables
 * can change and must be updated here respectively.
 */
const aliasing = {
    /** Alias: ModuleName|Field */
    aliases: {
        /** modules */
        modules: {
            dollarhandles: 'DollarHandles',
        },
        /** properties|data as field */
        fields: {
            amount: 'selectedValue',
        }
    },

    resolveModuleAlias(alias){
        return this.aliases.modules[alias.toLowerCase()];
    },

    resolveFieldAlias(alias){
        return this.aliases.fields[alias.toLowerCase()];
    }
}

const actions = {shown: 'shown', hide: 'hide'};


export { actions };
export const resolveModuleAlias = aliasing.resolveModuleAlias.bind(aliasing);
export const resolveFieldAlias = aliasing.resolveFieldAlias.bind(aliasing);
