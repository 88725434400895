<template>
  <div>
    <div
      v-for="(option, name, optionIndex) in settings.values"
      :key="optionIndex"
    >
      <input
        v-if="!(name ==='default')"
        :id="`answer-${optionIndex}`"
        type="radio"
        :value="option"
        name="option"
        :checked="isChecked(option, settings.values)"
        tabindex="0"
      >
      <label
        v-if="!(name ==='default')"
        :for="`option-${optionIndex}`"
        :title="option"
      >
        {{ option }}</label>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";

export default {
  name: "Options",
	mixins: [ModuleBase],
  props:{
      values: {
          type: Object,
          default: function () {
              return { }
          }
      }
  },
	mounted() {
		this.logger.logInfo(this.setings,'Dropdown component loaded')
	},

	methods: {
		isChecked(value, object){
			if(object['default'] === value) 
				return true;
			return false;
		}
	},
};
</script>
