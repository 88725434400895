<script>
import logger from "../../../common/logger";
import EmbeddedPaymentStore from "../EmbeddedPaymentStore";
import SimpleVueValidator from 'simple-vue-validator';
import Constants from '../EmbeddedPaymentConstants';
import * as GfLocalisation from '@gf/gf-localisation';
import _ from 'lodash';

export default {
    mixins: [SimpleVueValidator.mixin],

    props: {
        settings: {
            type: Object,
            default: () => null
        },

        displayedOnPageIndex: {
            type: Number,
            default: 0
        },

        additionalProperties: {
            type: Object,
            default: () => {},
        },
    },

    validators: { },

    data() {
        return {
            logger: logger,
            gfLocalisation: GfLocalisation,
            sharedState: EmbeddedPaymentStore.state,
            store: EmbeddedPaymentStore,
            isValid: true,
            validator: SimpleVueValidator.Validator,
            constants: Constants,
            uniqueId: '',
            entityConfigSettings: [],
            isParentModule: false,
            readyForSubmission: false,
        }
    },

    watch: {
        isValid(val) {
            var self = this;
            this.$nextTick(async () => await self.store.validate());
        },

        'store.state.entityConfigResults': function(val) {
            if (!val || val.length === 0 || this.entityConfigSettings.length === 0)
                return;

            this.filterEntityConfResults(val.Settings);
        },

        'store.state.prefillData': {
          deep: true,
          handler (fields) {
            if (fields.length == 0 && this.$options._componentTag)
              return
            
            const field = fields.find(f => {
              return f.name && this.$options._componentTag && f.name.toLowerCase() == this.$options._componentTag.toLowerCase()
            })
            if (typeof this.$options._componentTag != 'undefined' && field) {
              this.logger.logInfo(`watch.store.state.prefillData._componentTag: ${this.$options._componentTag}`)
              this.logger.logInfo('watch.store.state.prefillData.settings', JSON.parse(field.settings))
              this.$ModuleBase_handlePrefillData(JSON.parse(field.settings))
            }
          }
        }
    },

    mounted() {
        var self = this;
        this.uniqueId = this._uid;
        this.$nextTick(() => {
            if (!this.isParentModule)
                this.store.mountedComponent(this, this.displayedOnPageIndex)
            });

        this.entityConfigSettings.forEach(config => {
            self.store.fetchEntityConfig(config.type, config.subType)
        });

        this.$ModuleBase_afterBaseMounted();
    },

    methods: {
        $ModuleBase_afterBaseMounted() {
            // override
        },

        $ModuleBase_handleEntityConfigResults(results) {
            // override
        },

        $ModuleBase_handlePrefillData(settings) {
          // override
        },

        $ModuleBase_notifyParent(action, data) {
            this.logger.logInfo(`$ModuleBase_notifyParent:, emitting event ${action}`, data);
            this.$nextTick(() => this.$emit('action', {action: action, data: data}));
        },

        $ModuleBase_resized() {
            this.logger.logInfo(`$ModuleBase_resized:, contents resized`);
            this.$emit('action', {action: 'RESIZED', data: null});
        },

        async $ModuleBase_validate() {
            var result = await this.$validate();
            this.isValid = result;
            return result;
        },

        async $ModuleBase_executeBeforePayment() {
            return this.isValid;
        },

        filterEntityConfResults(val) {
            var self = this;
            var matchedResults = [];

            val.forEach(settings => {
                var result = self.entityConfigSettings.find(x=> x.type == settings.ConfigurationName && x.subType == settings.ConfigurationSubType);
                if (result) {
                    matchedResults.push(_.cloneDeep(settings.Settings))
                }
            });
            if (matchedResults.length > 0)
                this.$ModuleBase_handleEntityConfigResults(matchedResults);
        }
    }
}
</script>