<script>
import { nextTick } from 'vue';
import { resolveFieldAlias, resolveModuleAlias } from './ConditionalLogicConfig';
import conditionalRenderStore from './ConditionalLogicStateStore';

export default {
    name: 'ConditionalRenderMixin',
    props: {
        conditions: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            componentKey: this.$options.name,
            watchables: [],
            conditionalRenderStore,
            actionDictionary: {},
        }
    },
    watch: {
        'conditionalRenderStore.state': {
            async handler(){
                this.setupWatchableFields();
                await nextTick();
                if(!this.conditions || !this.conditions.length) return;
                this.conditions.forEach(condition => {
                    const storeState = this.conditionalRenderStore.state;
                    const module = resolveModuleAlias(condition.dependsOnModule);
                    const property = resolveFieldAlias(condition.property);
                    const stateValue = storeState[module] && storeState[module][property];
                    if(stateValue != null || stateValue != undefined)
                        this.evaluateCondition(condition, stateValue)
                })
            },
            immediate: true,
            deep: true,
        }
    },
    destroyed(){
        this.cleanupWatchers();
    },
    methods: {
        setupWatchableFields(){
            const isWatchable = Object.keys(this.conditionalRenderStore.state).includes(this.componentKey);
            if(!isWatchable) return;
            const watchableFields = Object.keys(this.conditionalRenderStore.state[this.componentKey]);
            watchableFields.forEach((wfield) => {
                const isWatched = this.watchables.find(watchedField =>
                watchedField.componentKey === this.componentKey &&
                watchedField.property === wfield);
                if(isWatched) return;
                if(this.$props.hasOwnProperty(wfield) || this.$data.hasOwnProperty(wfield)) {
                    this.setupDynamicWatcher(wfield);
                }
            })
        },
        setupDynamicWatcher(property) {
            const dynamicWatcher = this.$watch(property, (newVal) => {
                this.conditionalRenderStore.setState(this.componentKey, property, newVal);
            }, { immediate: true });
            this.watchables.push({ componentKey: this.componentKey, property, unwatch: dynamicWatcher });
        },
        cleanupWatchers() {
            this.watchables.forEach(watchable => watchable.unwatch());
            this.watchables = [];
        },
        registerAction(action, cb){
            this.actionDictionary[action] = cb;
        },
        evaluateCondition(condition, stateValue){
            let isValid = false;
            switch (condition.operator) {
                case '>':
                    isValid = (stateValue > condition.operand)
                    break;
                case '<':
                    isValid = (stateValue < condition.operand)
                    break;
                case '>=':
                    isValid = (stateValue >= condition.operand)
                    break;
                case '<=':
                    isValid = (stateValue <= condition.operand)
                    break;
                case '=':
                    isValid = (stateValue == condition.operand)
                    break;
            }
            if(!isValid) return;
            isValid && this.actionDictionary[condition.action] && this.actionDictionary[condition.action]();
        },
    },

}
</script>
